"use client";

import { FC } from "react";
import { useMountedState } from "react-use";

import { Card } from "@Entities/project";
import { CollectionProjectPreview, ResponseData } from "@Shared/api";
import { PAGES } from "@Shared/config";
import { useScreens } from "@Shared/hooks";
import { ElProps } from "@Shared/types";
import { AnimationFadeIn } from "@Shared/ui";
import { getImageSrcFromApiData, twcx } from "@Shared/utils";

export type ListProps = ElProps<"div"> & {
    projects: ResponseData<CollectionProjectPreview>[];
};

export const List: FC<ListProps> = ({ className, projects, ...restProps }) => {
    const isMounted = useMountedState();
    const { isLg } = useScreens();

    return (
        <div
            className={twcx(
                "grid gap-[38px]",
                "sm:grid-cols-2 sm:gap-x-3 sm:gap-y-10",
                "lg:grid-cols-3 lg:gap-x-4 lg:gap-y-14",
                className
            )}
            {...restProps}
        >
            {projects.map(({ attributes: project }, index) => {
                const isBig = !(index % 4);

                let link;
                let linkBlank = false;
                let image = project.imagePreview.data?.attributes;
                let animationDelay = 0;

                if (project.externalLink && project.useExternalLinkForPreview) {
                    link = project.externalLink.trim();
                    linkBlank = true;
                } else if (!project.useExternalLinkForPreview) {
                    link = PAGES.project(project.slug);
                }

                if (isBig && project.imagePreviewWide.data) {
                    image = project.imagePreviewWide.data.attributes;
                }

                if (isMounted() && !isBig && isLg) {
                    animationDelay = ((index % 4) - 1) * 150;
                }

                return (
                    <AnimationFadeIn key={project.slug} delay={animationDelay}>
                        <Card
                            className={twcx("max-sm:!order-none", "lg:!order-none", { "sm:col-span-full": isBig })}
                            style={{
                                order: isBig && index ? index + 2 : index,
                            }}
                            link={link}
                            linkBlank={linkBlank}
                            image={getImageSrcFromApiData(image)}
                            imageDevices={getImageSrcFromApiData(project.imagePreviewDevices.data?.attributes)}
                            badges={project.badges?.map(b => b.name)}
                            name={project.name}
                            description={project.description}
                            data={isBig ? (project.data?.map(d => ({ key: d.label, value: d.text })) ?? []) : undefined}
                            dataPosition={index % 8 ? "left" : "right"}
                        />
                    </AnimationFadeIn>
                );
            })}
        </div>
    );
};
