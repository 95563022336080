"use client";

import { FC } from "react";

import { MODALS } from "@Shared/config";
import { useModalState } from "@Shared/store";
import { Button, ButtonProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

import styles from "./styles.module.scss";

export type ButtonContactUsProps = ButtonProps;

export const ButtonContactUs: FC<ButtonContactUsProps> = ({ children, ...restProps }) => {
    const { open } = useModalState();

    return (
        <Button
            className={twcx("mt-3 w-full", "md:max-w-[389px]", "lg:mt-4")}
            classNames={{ textIconIcon: styles.lightning }}
            icon="lightning"
            onClick={() => open(MODALS.contactUs)}
            {...restProps}
        >
            {children}
        </Button>
    );
};
