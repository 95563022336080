import { forwardRef } from "react";

import { ComponentPageHomeSectionAbout } from "@Shared/api";
import { ElProps } from "@Shared/types";
import { twcx } from "@Shared/utils";

import ImageCursor from "./images/cursor.svg";
import styles from "./styles.module.scss";

export type CursorsProps = ElProps<"div"> & {
    list?: ComponentPageHomeSectionAbout["cursors"];
};

export const Cursors = forwardRef<HTMLDivElement, CursorsProps>(({ className, list, ...restProps }, ref) => {
    if (!list?.length) {
        return;
    }

    return (
        <div ref={ref} className={twcx("pointer-events-none absolute inset-0 select-none", className)} {...restProps}>
            {list.map((cursor, index) => (
                <div
                    key={index + cursor}
                    className={twcx("about-cursor absolute left-1/2 top-1/2 will-change-transform", styles.cursor)}
                >
                    <ImageCursor className={twcx("w-[37px] text-blue-light", "lg:w-[41px]")} />

                    <div
                        className={twcx(
                            "absolute left-full top-full -ml-3 -mt-1 whitespace-nowrap bg-blue-light px-2 py-1 text-center font-secondary text-[18px] leading-[22px] text-black shadow-[0_3px_7px_0_rgba(0,0,0,0.12)]",
                            "lg:-ml-2 lg:-mt-1.5 lg:text-[20px] lg:leading-[24px]"
                        )}
                    >
                        {cursor}
                    </div>
                </div>
            ))}
        </div>
    );
});
